body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

/* create a variable for main color */
:root {
    --primary-color: whitesmoke;
    --new-primary-color: #A76658;
    --new-primary-color-dark: #6c362a;
    --secondary-color: #BF5B04;
    background-color: var(--primary-color);
    /* background-image: url('../public/background.jpeg'); /* Ensure the image is in the public directory */
    /* background-repeat: repeat; */ 
    background-size: 200px 100px;
}

input:focus {
    /* change outline color */
    outline-color: var(--new-primary-color);
}

textarea:focus {
    /* change outline color */
    outline-color: var(--new-primary-color);
}

